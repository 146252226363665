import React, { useState } from 'react'

import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import { ListGroup } from 'react-bootstrap';
import { handleInputChange } from './_helpers/form/handleInputChange';
import { CopyBlock, atomOneLight } from "react-code-blocks";

function RebaseDevMaster() {


    const [branchName, setBranchName] = useState('')
    const [message, setMessage] = useState('')

    /**
    * function to handle input changes and modify the value
    * @param {string} e.name input name
    * @param {string} e.value input value
    * @author Akshay N
    */
    function handleChange(e) {
        var { value } = handleInputChange(e);

        setBranchName(value)
    }

    /**
   * function to handle input changes and modify the value
   * @param {string} e.name input name
   * @param {string} e.value input value
   * @author Akshay N
   */
    function handleMessageChange(e) {
        var { value } = handleInputChange(e);

        // value = value.replace(/\s+/g, '-').toLowerCase();
        value = value.replace(/\//g, '-');
        value = value.replace(/_/g, '-');

        setMessage(value);

    }

    return (
        <React.Fragment>
            <Card className="mb-3">
                <Card.Body>
                    <Row className="ms-2 w-100">
                        <Col>
                            <h6>Get current branch name</h6>
                            <CopyBlock
                                language={"c"}
                                // eslint-disable-next-line no-useless-escape
                                text={"git branch --show-current"}
                                showLineNumbers={false}
                                theme={atomOneLight}
                                wrapLines={true}
                                codeBlock
                            />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card className="mb-3">
                <Card.Body>
                    <ListGroup as="ol" numbered>
                        <ListGroup.Item as="li" className="d-flex align-items-start b-none">
                            <Row className="ms-2 w-100">
                                <Col>
                                    <h6>Current branch name</h6>
                                    <Form.Control placeholder="Enter current branch name" onChange={handleChange} value={branchName} />
                                </Col>
                            </Row>
                        </ListGroup.Item>
                        <ListGroup.Item as="li" className={`d-flex align-items-start b-none ${(!branchName) ? 'not-available' : ''}`}>
                            <Row className="ms-2 w-100">
                                <Col>
                                    <h6>Commit current changes</h6>
                                    <InputGroup className="mt-2">
                                        <InputGroup.Text>Commit Message</InputGroup.Text>
                                        <Form.Control placeholder="Enter Message" name='message' value={message} onChange={handleMessageChange} />
                                    </InputGroup>
                                    <div className={`${(!message) ? 'not-available' : ''}`}>
                                        <CopyBlock
                                            language={"c"}
                                            // eslint-disable-next-line no-useless-escape
                                            text={message ? `git add . \ngit commit -m \"${message}\" ` : `git add . \n// message not added `}
                                            showLineNumbers={false}
                                            theme={atomOneLight}
                                            wrapLines={true}
                                            codeBlock
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </ListGroup.Item>
                        <ListGroup.Item as="li" className={`d-flex align-items-start b-none ${(!branchName || !message) ? 'not-available' : ''}`}>
                            <Row className="ms-2 w-100">
                                <Col>
                                    <h6>Fetch and download content from a remote repository and immediately update the local repository to match that content.Then rebase the dev-master to the branch.</h6>
                                    <CopyBlock
                                        language={"c"}
                                        // eslint-disable-next-line no-useless-escape
                                        text={`git checkout dev-master \ngit pull origin dev-master \ngit checkout \"${branchName}\" \ngit rebase dev-master`}
                                        showLineNumbers={false}
                                        theme={atomOneLight}
                                        wrapLines={true}
                                        codeBlock
                                    />
                                </Col>
                            </Row>
                        </ListGroup.Item>
                    </ListGroup>
                </Card.Body>
            </Card>
        </React.Fragment>

    )
}

export { RebaseDevMaster }