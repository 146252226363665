import React from 'react'

import { ReleaseFeature } from './ReleaseFeature';
import { ReleaseBug } from './ReleaseBug';
import { Accordion, Card } from 'react-bootstrap';
import { CopyBlock, atomOneLight } from "react-code-blocks";

function DeployRelease() {

    return (
        <React.Fragment>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header><h4 className='mb-0'>DEPLOY A NEW FEATURE</h4></Accordion.Header>
                    <Accordion.Body>
                        <ReleaseFeature />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header><h4 className='mb-0'>DEPLOY A BUG</h4></Accordion.Header>
                    <Accordion.Body>
                        <ReleaseBug />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>

            <Card className='mt-4 mb-4'>
                <Card.Body>
                    <Card.Title className='mt-4 mb-4'>Deploy Title and Description</Card.Title>

                    <Card.Text>
                        <CopyBlock
                            // language={"c"}
                            // eslint-disable-next-line no-useless-escape
                            text={"Bug Fixes and Stability Improvements"}
                            showLineNumbers={false}
                            theme={atomOneLight}
                            wrapLines={true}
                            codeBlock
                        />
                        <CopyBlock
                            language={"text"}
                            // eslint-disable-next-line no-useless-escape
                            text={"This release focuses on resolving various bugs and enhancing the overall stability of the project. \nWe have addressed critical issues and made improvements to ensure a smoother and more reliable user experience. \nHere's an overview of the changes: \n\n - Fixed an issue causing intermittent crashes when accessing certain features.\n - Resolved a bug that caused incorrect data rendering in specific scenarios.\n - Addressed a memory leak that could impact performance over extended usage.\n - Improved error handling and error messages for better troubleshooting.\n - Enhanced overall system stability and performance.\n\nWe recommend all users to update to this latest version to benefit from these bug fixes and stability improvements. \nThank you for your continued support and valuable feedback, which helps us make the project more robust and reliable."}
                            showLineNumbers={false}
                            theme={atomOneLight}
                            wrapLines={true}
                            codeBlock
                        />

                    </Card.Text>
                </Card.Body>
            </Card>

        </React.Fragment>
    )
}

export { DeployRelease }