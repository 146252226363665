import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { NewFeature } from './NewFeature';
import { RebaseDevMaster } from './RebaseDevMaster';
import { DeployRelease } from './DeployRelease';

function App() {
  return (
    <Container style={{ marginTop: `2%` }}>
      <h1 className="header">Git Commands</h1>

      <Tabs id="uncontrolled-tab-example" className="mt-3 mb-3" >
        <Tab eventKey="feature" title="Develop a new feature" className='ps-4 pe-4 mt-4'>
          <NewFeature />
        </Tab>
        <Tab eventKey="rebase" title="Rebase Dev-Master" className='ps-4 pe-4 mt-4'>
          <RebaseDevMaster />
        </Tab>
        <Tab eventKey="deploy" title="Deploy a Release" className='ps-4 pe-4 mt-4'>
          <DeployRelease />
        </Tab>
      </Tabs>
    </Container>
  );
}

export default App;
